import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { BiUser } from "react-icons/bi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import Checkbox from "react-custom-checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Config from "../Config";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { ADD_USER } from "../Redux/actions";
import { useTranslation } from "react-i18next";


const Login = () => {
  const { t, i18n } = useTranslation();
  const [isOTPVisible, setisOTPVisible] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [plan, setPlan] = useState("1");
  const [billingType, setBillingType] = useState("1");


  const dispatch = useDispatch();

  // register api
  const registerFunction = (body) => {
    return axios.post(Config.baseUrl + "register", body);
  };

  const registerSuccess = (resp) => {
    if (resp.data.status === "OK") {
      setisOTPVisible(true);
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: registerLoading, mutate: registerMutate } = useMutation(
    registerFunction,
    {
      onSuccess: registerSuccess,
    }
  );
  // end of register api

  // OTP api
  const otpFunction = (body) => {
    return axios.post(Config.baseUrl + "otp", body);
  };

  const otpSuccess = (resp) => {
    if (resp.data.status === "OK") {
      dispatch(
        ADD_USER({
          mobile,
          points: resp.data.points,
          unlockedGames: resp.data.unlockgame,
          userName: resp.data.name,
          playedGames: resp.data.playedGames,
          rank: resp.data.rank,
        })
      );
      if (resp.data.isNewUser == 'Y')
        navigate("/plans");
      else
        navigate("/");
    } else {
      toast.error(resp.data.msg || "An Error Occured");
    }
  };

  const { isLoading: otpLoading, mutate: otpMutate } = useMutation(
    otpFunction,
    {
      onSuccess: otpSuccess,
    }
  );
  // end of OTP api

  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();

    // number
    if (!isOTPVisible) {
      if (!mobile || mobile.length !== 9) {
        toast.error("Please enter a valid mobile number");
        return;
      }

      const body = new FormData();
      body.append("mobile", mobile);
      body.append("plan", plan);
      body.append("billingType", billingType);
      //body.append("billingType", 2);
      registerMutate(body);
      setOtp("");
    }

    //OTP
    else {
      if (!otp || otp.length !== 4) {
        toast.error("Please enter a valid OTP");
        return;
      }

      const body = new FormData();
      body.append("mobile", mobile);
      body.append("otp", otp);
      otpMutate(body);
    }
  };

  return (
    <>
    <Navbar />
      <Wrapper>
        
        <ImageWrapper>
          <TextWrapper>
            <h1 className="text-6xl font-bold mt-28 ">
            {t("logintitle")}<br />{t("logintitle1")} <br />{t("logintitle2")}.
              {/* The home of <br /> everything <br /> football. */}
            </h1>
            <p className="text-2xl font-medium mt-14 w-10/12 ">
            {t("loginsubtitle")}
              
            </p>
          </TextWrapper>
          <img
            src={Images.LoginBg}
            alt=""
            className="w-full h-full object-cover"
          />
        </ImageWrapper>
        <Container>
          <img src={Images.LogoWhite} className="w-24" alt="" />

          <h1 className="text-2xl text-white font-medium mt-10">
            {isOTPVisible ? t("OTP") : t("Login")}
          </h1>

          <form onSubmit={submitHandler}>
            <p className="text-gray-200 mt-4 text-sm">
              {isOTPVisible ? (
                <p>
                  {t("SMSSent")} <b className="font-bold text-base">{mobile}</b>{" "}
                </p>
              ) : (
                <p>
                  {t("loginright_title")}
                  
                </p>
              )}
            </p>

            <InputGroup>
              {isOTPVisible ? (
                <Input
                  type="number"
                  placeholder={t("enter_otp")}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              ) : (
                <Input
                  type="number"
                  placeholder={t("enter_number")}
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              )}
            </InputGroup>
            {!isOTPVisible && (
              <>
              <InputGroup>
                  <Select
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      {t("SelectPlan")}
                    </option>
                    <option value="1">{t("daily")}</option>
                    <option value="7">{t("weekly")}</option>
                    <option value="30">{t("Monthly")}</option>
                  </Select>
                </InputGroup>
                <InputGroup>
                  <Select
                    value={billingType}
                    onChange={(e) => setBillingType(e.target.value)}
                    required
                  >
                   
                    <option value="1">{t("auto")}</option>
                    <option value="2">{t("onetime")}</option>
                  </Select>
                </InputGroup>
                </>
              )}
            <Btn type="submit">
              {(registerLoading || otpLoading) && (
                <ReactLoading
                  type={"spin"}
                  color={"white"}
                  height={25}
                  width={25}
                />
              )}
              {!registerLoading &&
                !otpLoading &&
                (isOTPVisible ? t("ver_Otp") : t("Getotp"))}
            </Btn>
          </form>

          {/* <p className="text-light-blue text-sm md:text-base mt-10 text-center lg:text-left ">
            {t("charge")}
          </p> */}

          <footer className="text-white flex justify-center items-center space-x-4 text-xs mt-auto  pb-6  w-full">
            <p>{t("terms")}</p> <p>|</p> <p>{t("privacy")}</p>
          </footer>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = tw.section`bg-gradient-to-b from-primary-color to-blue-900 relative overflow-hidden min-h-screen md:flex   `;

const ImageWrapper = tw.div`h-screen lg:w-7/12 hidden lg:block relative`;

const TextWrapper = tw.div`Container w-full h-full absolute top-0 left-0 bg-gradient-to-r pt-16 mx-auto lg:px-12 from-transparent-blue z-10 text-white`;

const Container = tw.div`Container min-h-screen h-full flex flex-col pt-16 lg:w-5/12 sm:max-w-md mx-auto  lg:px-8`;

const Input = tw.input`border-b text-lg bg-transparent outline-none border-white py-5 mt-6 w-full placeholder:text-slate-400 text-white `;

const InputGroup = tw.div`relative`;

const Btn = tw.button`w-full bg-gradient-to-r from-light-blue to-dark-blue rounded-full h-12 overflow-hidden flex justify-center items-center   font-medium  text-white mt-14 mb-auto  cursor-pointer`;

const Select = tw.select`options w-full border-b border-gray-200 focus:outline-0 text-xl md:text-base bg-transparent  text-white font-normal px-2 py-3 mt-4`;


export default Login;
